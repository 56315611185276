<template>
  <PrimaryTemplate
    :step="step"
    :buttonFunction="submit"
    :mainTitle="'Education'"
    :needBackButton="true"
    :backFunction="back"
    :loading="loading"
  >
    <div class="headline-wrapper">
      <h2 class="card-heading">Educational institutions</h2>
      <AddButtonComponent
        addText="education"
        @click="handleNewModelAdd('education')"
      />
    </div>
    <div class="card-content-area">
      <p class="sub-text" v-if="Object.keys(educationList).length === 0">
        Indicate the educational institutions you graduated from
      </p>
      <div
        class="single-card-wrapper"
        v-for="(value, name, index) in educationList"
        :key="name"
      >
        <SingleEducation
          :index="index"
          :id="name"
          :educationTitle="value.institution"
          :educationFaculty="value.faculty"
          :educationSpecialty="value.specialty"
          :educationBeginDate="value.beginDate"
          :educationEndDate="value.endDate"
          @update:modelValue="handleChildModelChange"
          @modelRemove="handleChildModelRemove"
        />
      </div>
    </div>

    <div class="headline-wrapper">
      <h2 class="card-heading">Courses</h2>
      <AddButtonComponent addText="course" @click="handleNewModelAdd('course')" />
    </div>
    <div class="card-content-area">
      <p class="sub-text" v-if="Object.keys(courses).length === 0">
        List the courses you have taken (if any)
      </p>
      <div
        class="single-card-wrapper"
        v-for="(value, name, index) in courses"
        :key="name"
      >
        <SingleCourse
          :index="index"
          :id="name"
          :courseName="value.nameCourse"
          :courseTitle="value.specialty"
          :courseAuthor="value.author"
          :courseBeginDate="value.beginDate"
          :courseEndDate="value.endDate"
          @update:modelValue="handleChildModelChange"
          @modelRemove="handleChildModelRemove"
        />
      </div>
    </div>

    <div class="headline-wrapper">
      <h2 class="card-heading">Foreign languages</h2>
      <AddButtonComponent
        addText="language"
        @click="handleNewModelAdd('language')"
      />
    </div>
    <div class="card-content-area">
      <p class="sub-text" v-if="Object.keys(languages).length === 0">
        Indicate your level of knowledge of foreign languages
      </p>
      <div
        class="single-card-wrapper"
        v-for="(value, name, index) in languages"
        :key="name"
      >
        <SingleLanguage
          :index="index"
          :id="name"
          :languageTitle="value.language"
          :languageLevel="value.level"
          @update:modelValue="handleChildModelChange"
          @modelRemove="handleChildModelRemove"
        />
      </div>
    </div>
    <div v-if="errorMessage" class="error-message red">
      {{ errorMessage }}
    </div>
  </PrimaryTemplate>
</template>
<script>
import PrimaryTemplate from "@/layouts/PrimaryTemplate.vue";

import SingleLanguage from "@/components/utility/SingleLanguage.vue";
import SingleCourse from "@/components/utility/SingleCourse.vue";
import SingleEducation from "@/components/utility/SingleEducation.vue";
import AddButtonComponent from "../components/utility/AddButtonComponent";

import { generateUID } from "../utils/generateUID";
import { update } from "../api/formdata";

import useVuelidate from "@vuelidate/core";

export default {
  name: "Education",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      step: "Education",
      levels: [
        "A1 - Beginner/Elementary",
        "A2 - Pre-Intermediate",
        "B1 - Intermediate",
        "B2 - Upper-Intermediate",
        "C1 - Advance",
        "C2 - Proficient",
      ],
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    educationList: function () {
      return this.$store.getters.getEducationList;
    },
    courses: function () {
      return this.$store.getters.getCourses;
    },
    languages: function () {
      return this.$store.getters.getLanguages;
    },
  },
  methods: {
    // Common methods
    handleNewModelAdd(type) {
      switch (type) {
        case "education":
          // Add new empty education to the store
          this.$store.dispatch("updateEducation", {
            id: generateUID(),
            title: "",
            faculty: "",
            specialty: "",
            beginDate: "",
            endDate: "",
          });

          break;

        case "course":
          // Add new empty course to the store
          this.$store.dispatch("updateCourse", {
            id: generateUID(),
            name: "",
            title: "",
            author: "",
            beginDate: "",
            endDate: "",
          });

          break;

        case "language":
          // Add new empty language to the store
          this.$store.dispatch("updateLanguage", {
            id: generateUID(),
            language: "",
            level: "",
          });
          break;

        default:
          break;
      }
    },
    handleChildModelChange(payload) {
      const { type, value } = payload;

      switch (type) {
        case "education":
          this.$store.dispatch("updateEducation", value);
          break;

        case "course":
          this.$store.dispatch("updateCourse", value);
          break;

        case "language":
          this.$store.dispatch("updateLanguage", value);
          break;

        default:
          break;
      }
    },
    handleChildModelRemove(payload) {
      const { type, id } = payload;

      switch (type) {
        case "education":
          this.$store.dispatch("removeEducation", id);
          break;

        case "course":
          this.$store.dispatch("removeCourse", id);
          break;

        case "language":
          this.$store.dispatch("removeLanguage", id);
          break;

        default:
          break;
      }
    },
    handleSingleLanguageModelUpdate(value) {
      this.$store.dispatch("updateLanguage", {
        id: value.id,
        language: value.language,
        level: value.level,
      });
    },

    // Bottom buttons
    async submit() {
      this.v$.$touch();
      let modifyLoading = this.modifyLoading;
      let setError = this.setError;
      let router = this.$router;

      if (!this.v$.$invalid) {
        modifyLoading(true);
        setError(null);

        let thisData = {
          educationList: this.toArray(this.educationList),
          courses: this.toArray(this.courses),
          languages: this.toArray(this.languages),
        };
        let currentData = JSON.parse(localStorage.getItem("data"));
        currentData.data.push(thisData);

        await update(currentData).then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            modifyLoading(false);

            let thisDataStringified = JSON.stringify(currentData);
            localStorage.setItem("data", thisDataStringified);
            router.push({ path: "/experience" });
          } else {
            modifyLoading(false);
            if (response.status == 400) {
              setError(
                "Validation error! Please check the accuracy of the entered data!"
              );
            } else {
              setError("Form submission error! Please try again later!");
            }
            console.log("Error: ");
            console.log(response.status);
          }
        });
      }
    },
    back() {
      let currentData = JSON.parse(localStorage.getItem("data"));
      currentData.data.pop();
      let thisDataStringified = JSON.stringify(currentData);
      localStorage.setItem("data", thisDataStringified);

      this.$router.push({ path: "/expectations" });
    },
    toArray(data) {
      let array = [];
      Object.values(data).forEach((val) => {
        array.push(val);
      });
      return array;
    },
    modifyLoading(loading) {
      this.loading = loading;
    },
    setError(message) {
      this.errorMessage = message;
    },
  },
  components: {
    PrimaryTemplate,
    SingleEducation,
    SingleCourse,
    SingleLanguage,
    AddButtonComponent,
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.card-heading {
  color: $text-black;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 25px;
  text-align: left;
  width: 100%;
  @media (min-width: 320px) and(max-width: 375px) {
    font-size: 16px;
  }
}

.headline-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 320px) and(max-width: 455px) {
    flex-direction: column;

    .card-heading {
      margin-bottom: 10px;
    }

    .add-button-wrapper {
      max-width: 100%;
      justify-content: flex-start;
      margin-bottom: 10px;
    }
  }
}

.card-content-area {
  width: 100%;
  margin-bottom: 40px;

  .sub-text {
    color: $text-grey;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    min-height: 20px;
    display: flex;
  }
}
.red {
  color: red;
}
:disabled {
  opacity: 0.4;
}
</style>
