<template>
  <div class="single-element-wrapper">
    <h3 class="single-element-heading">Language #{{ index + 1 }}</h3>
    <div class="items-wrapper">
      <div class="single-item">
        <TextInput
          name="foreign-language"
          :labelText="`Language #${index + 1}`"
          placeholder="Enter language"
          v-model="language"
          :validationMessage="errorMessages.language"
        />
      </div>
      <div class="single-item">
        <DropdownInput
          labelText="Proficiency level"
          :options="levels"
          placeholderText="Choose a level"
          v-model="level"
        />
      </div>
    </div>
    <div class="remove-button" @click="handleModelRemove">
        Delete language
    </div>
  </div>
</template>

<script>
import TextInput from "./TextInput";
import DropdownInput from "./DropdownInput";

import useVuelidate from "@vuelidate/core";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { helpers, maxLength } from "@vuelidate/validators";
import { noDigits } from "../../validations/custom";

export default {
  name: "SingleLanguange",
  props: {
    id: String,
    languageTitle: {
      type: String,
      default: "",
    },
    languageLevel: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:modelValue", "modelRemove"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      language: this.languageTitle,
      level: this.languageLevel,
      levels: [
        "A0/A1 - Beginner/Elementary",
        "A2 - Pre-Intermediate",
        "B1 - Intermediate",
        "B2 - Upper-Intermediate",
        "C1 - Advanced",
        "C2 - Proficient",
      ],
    };
  },
  computed: {
    languageModel: function () {
      return {
        id: this.id,
        language: this.language,
        level: this.level,
      };
    },
    errorMessages: function () {
      return {
        language: getErrorMessage(this.v$.language.$errors),
      };
    },
  },
  watch: {
    languageModel: function () {
      const payload = {
        type: "language",
        value: this.languageModel,
      };

      this.$emit("update:modelValue", payload);
    },
  },
  methods: {
    handleModelRemove() {
      const payload = {
        type: "language",
        id: this.id,
      };

      this.$emit("modelRemove", payload);
    },
  },
  components: {
    TextInput,
    DropdownInput,
  },
  validations() {
    return {
      language: {
        noDigits: helpers.withMessage("Digits are not allowed", noDigits),
        maxLength: maxLength(50),
        $autoDirty: true,
      },
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/single-element-card.scss";

.items-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;

  .single-item {
    max-width: 47%;
    width: 100%;

    .dropdown-input-wrapper {
      padding-bottom: 0px;
    }
  }

  @media screen and (max-width: 519.88px) {
    .single-item {
      max-width: 100%;
      width: 100%;
    }
  }
}
.single-language-validation-message-wrapper {
  min-height: 25px;
}

.single-element-wrapper .remove-button {
  margin-top: 0px;

  @media screen and (max-width: 519.88px) {
    margin-top: 20px;
  }
}
</style>
