<template>
  <div class="single-element-wrapper">
    <h3 class="single-element-heading">
        Course #{{ index + 1 }}
      {{ nameCourse.length !== 0 ? `- ${nameCourse}` : "" }}
    </h3>
    <TextInput
      name="nameCourse"
      labelText="Course name"
      placeholder="Enter course name"
      v-model="nameCourse"
      :validationMessage="errorMessages.nameCourse"
    />
    <TextInput
      name="specialty"
      labelText="Speciality"
      placeholder="Indicate specialty"
      v-model="specialty"
      :validationMessage="errorMessages.specialty"
    />
    <TextInput
      name="author"
      labelText="Course author"
      placeholder="Specify the author of the course"
      v-model="author"
      :validationMessage="errorMessages.author"
    />
    <div class="dates-wrapper">
      <UniversalMonthPicker
        labelText="Start date"
        placeholder="Choose date"
        v-model="beginDate"
      />
      <UniversalMonthPicker
        labelText="End date"
        placeholder="Choose date"
        v-model="endDate"
      />
    </div>
    <div class="remove-button" @click="handleModelRemove">Delete course</div>
  </div>
</template>

<script>
import TextInput from "../utility/TextInput";
import UniversalMonthPicker from "../utility/UniversalMonthPicker";

import useVuelidate from "@vuelidate/core";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { helpers, maxLength } from "@vuelidate/validators";
import { noDigits } from "../../validations/custom";

export default {
  name: "SingleCourse",
  props: {
    id: String,
    index: {
      type: Number,
      default: 0,
    },
    courseName: {
      type: String,
      default: "",
    },
    courseTitle: {
      type: String,
      default: "",
    },
    courseAuthor: {
      type: String,
      default: "",
    },
    courseBeginDate: {
      type: String,
      default: "",
    },
    courseEndDate: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "modelRemove"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      nameCourse: this.courseName,
      specialty: this.courseTitle,
      author: this.courseAuthor,
      beginDate: this.courseBeginDate,
      endDate: this.courseEndDate,
    };
  },
  watch: {
    courseModel: function () {
      this.handleModelChange();
    },
  },
  computed: {
    isValid: function () {
      return !this.v$.$invalid;
    },
    courseModel: function () {
      return {
        id: this.id,
        nameCourse: this.nameCourse,
        specialty: this.specialty,
        author: this.author,
        beginDate: this.beginDate,
        endDate: this.endDate,
      };
    },
    errorMessages: function () {
      return {
        nameCourse: getErrorMessage(this.v$.nameCourse.$errors),
        specialty: getErrorMessage(this.v$.specialty.$errors),
        author: getErrorMessage(this.v$.author.$errors),
      };
    },
  },
  methods: {
    handleModelChange() {
      const payload = {
        type: "course",
        value: this.courseModel,
      };

      this.$emit("update:modelValue", payload);
    },
    handleModelRemove() {
      const payload = {
        type: "course",
        id: this.id,
      };

      this.$emit("modelRemove", payload);
    },
  },
  components: {
    TextInput,
    UniversalMonthPicker,
  },
  validations() {
    return {
      nameCourse: {
        maxLength: maxLength(50),
        $autoDirty: true,
      },
      specialty: {
        noDigits: helpers.withMessage("Digits are not allowed", noDigits),
        maxLength: maxLength(50),
        $autoDirty: true,
      },
      author: {
        noDigits: helpers.withMessage("Digits are not allowed", noDigits),
        maxLength: maxLength(50),
        $autoDirty: true,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/single-element-card.scss";
</style>
